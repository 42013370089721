@if (location.hostname.includes('localhost') && !isCustomToolbarHidden) {
  <mat-toolbar [color]="'primary'">
    <button (click)="settingsService.setTheme('light-theme', true)" color="primary" mat-icon-button>
      <mat-icon>light_mode</mat-icon></button
    >&nbsp;-&nbsp;<button (click)="settingsService.setTheme('dark-theme', true)" color="primary" mat-icon-button>
      <mat-icon>dark_mode</mat-icon>
    </button>
    <mat-divider [vertical]="true"></mat-divider>
    <button (click)="translationService.setLocale('de')" color="primary" mat-raised-button>DE</button>&nbsp;-&nbsp;<button
      (click)="translationService.setLocale('en')"
      color="accent"
      mat-raised-button
    >
      EN
    </button>
    <div class="flex-spacer"></div>
    <button [matMenuTriggerFor]="menu" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu" [class]="'navigation-menu'">
      <div class="left-column">
        <button [routerLink]="['/dashboard']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-dashboard'"></mat-icon>
          Dashboard
        </button>
        <button [routerLink]="['/files']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-files'"></mat-icon>
          Dateien
        </button>
        <button [routerLink]="['/documents/baskets']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-basket'"></mat-icon>
          Warenkörbe
        </button>
        <button [routerLink]="['/documents/offers']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-offers'"></mat-icon>
          Angebote
        </button>
        <button [routerLink]="['/documents/orders']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-orders'"></mat-icon>
          Aufträge
        </button>
        <button [routerLink]="['/openpositions']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-open_positions'"></mat-icon>
          Offene Positionen
        </button>
        <button [routerLink]="['/documents/deliveries']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-delivery_notes'"></mat-icon>
          Lieferscheine
        </button>
        <button [routerLink]="['/documents/invoices']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-invoices'"></mat-icon>
          Rechnungen
        </button>
        <button [routerLink]="['/documents/creditnotes']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-credit_notes'"></mat-icon>
          Gutschriften
        </button>
        <button [routerLink]="['/credits']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-credits'"></mat-icon>
          Credits
        </button>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div class="right-column">
        <button [routerLink]="['/assets']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-assets'"></mat-icon>
          Assets
        </button>
        <button [routerLink]="['/licenses']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-licenses'"></mat-icon>
          Lizenzen
        </button>
        <button [routerLink]="['/servicetickets']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-servicetickets'"></mat-icon>
          Service Tickets
        </button>
        <button [routerLink]="['/billingaccounts']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-billingaccounts'"></mat-icon>
          Billingaccounts
        </button>
        <button [routerLink]="['/servicevouchers']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-servicevouchers'"></mat-icon>
          Service Scheine
        </button>
        <button [routerLink]="['/account']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-person'"></mat-icon>
          Meine Daten
        </button>
        <button [routerLink]="['/addresses']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-addresses'"></mat-icon>
          Adressen
        </button>
        <button [routerLink]="['/costcenters']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-costcenters'"></mat-icon>
          Kostenstellen
        </button>
        <button [routerLink]="['/users']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-users'"></mat-icon>
          Benutzer
        </button>
        <button [routerLink]="['/profiles']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-user_profiles'"></mat-icon>
          Benutzer Profile
        </button>
      </div>
      <mat-divider></mat-divider>
      <div>
        <button [routerLink]="['/settings']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-settings'"></mat-icon>
          Einstellungen
        </button>
        <button [routerLink]="['/feedback']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-feedback'"></mat-icon>
          Feedback senden
        </button>
        <button [routerLink]="['/logout']" mat-menu-item>
          <mat-icon [svgIcon]="'enthus-logout'"></mat-icon>
          Abmelden
        </button>
        <button (click)="isCustomToolbarHidden = !isCustomToolbarHidden" mat-menu-item>
          <mat-icon>hide_source</mat-icon>
          Hide header
        </button>
      </div>
    </mat-menu>
    <button [routerLink]="['/cart']" mat-icon-button>
      <mat-icon [svgIcon]="'enthus-shopping_cart'"></mat-icon>
    </button>
  </mat-toolbar>
}
