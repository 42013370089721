import { HttpClient } from '@angular/common/http';
import { ElementRef, EventEmitter, Injectable, NgZone } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as sentry from '@sentry/angular';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { ENVIRONMENT } from '../../../environments/environment';
import { ErrorDialogComponent } from '../../error-dialog/error-dialog.component';
import {
  IPayPalAmountWithBreakdown,
  IPayPalApplicationContext,
  IPayPalButtonStyle,
  IPayPalOrder,
  IPayPalPayer,
  IPayPalPurchaseUnit,
  IPayPalPurchaseUnitItem,
  IPayPalShipping,
} from '../../interfaces/paypal.interface';
import { PayPalPaymentCancelledComponent } from '../../paypal/paypal-payment-cancelled/paypal-payment-cancelled.component';
import { IAddress } from '../address.service';
import { AppService, SentryScopeLevel } from '../app.service';
import { BaseCartService } from '../base-cart.service';
import { DialogService } from '../dialog.service';
import { TranslationService } from '../translation.service';
import { UserService } from '../user.service';
import {
  PayPalCurrencyCodes,
  PayPalENV,
  PayPalINTENT,
  PayPalLandingPage,
  PayPalPayeePreferred,
  PayPalShippingPreference,
  PayPalUserAction,
} from './paypal.enums';
import { IOrderCartResponse } from '../../interfaces/cart.interface';

@Injectable({
  providedIn: 'root',
})
export class PayPalService {
  public deliveryAddress: IAddress;
  public paymentStarted: EventEmitter<void> = new EventEmitter<void>();
  public paymentCancelled: EventEmitter<void> = new EventEmitter<void>();
  private _payPalScriptElement: HTMLScriptElement;
  private _paypalFeedBackDialog: MatDialogRef<ErrorDialogComponent | PayPalPaymentCancelledComponent>;
  public showPayPalLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _httpClient: HttpClient,
    private _ngZone: NgZone,
    private _cartService: BaseCartService,
    private _userService: UserService,
    private _translationService: TranslationService,
    private _dialogService: DialogService,
    private _appService: AppService
  ) {}

  public renderButton(elementRef: ElementRef, orderCartFunction: (paypalOrderId: string) => Observable<IOrderCartResponse>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._ngZone.runOutsideAngular(() => {
        this.loadPayPalScript()
          .then(() => {
            window.paypal
              .Buttons({
                env: this._appService.usePayPalProd.value ? PayPalENV.PRODUCTION : PayPalENV.SANDBOX,
                style: this._getPayPalButtonStyle(),
                fundingSource: window.paypal.FUNDING.PAYPAL,
                onCancel: () => this._ngZone.run(() => this._openFeedbackDialog()),
                onError: () => this._ngZone.run(() => this._openErrorDialog()),
                onApprove: (data, actions) => {
                  return this._ngZone.run(() => {
                    return (
                      firstValueFrom(orderCartFunction(data.orderID))
                        // firstValueFrom(orderCartFunction(data.orderID, { mock_application_codes: 'INSTRUMENT_DECLINED' })) // This is to simulate payment issues
                        .catch((error) => {
                          if (error.error?.PayPalError) {
                            const errorDetail = Array.isArray(error.error?.PayPalError.details) && error.error?.PayPalError.details[0];

                            if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
                              actions.restart(); // Recoverable state, per: https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
                            }

                            if (errorDetail) {
                              let msg = 'Transaction could not be processed.';
                              if (errorDetail.description) {
                                msg += '\n\n' + errorDetail.description;
                              }
                              if (error.error.PayPalError.orderData.debug_id) {
                                msg += ' (' + error.error.PayPalError.orderData.debug_id + ')';
                              }

                              sentry.withScope((scope) => {
                                scope.setTag('component', 'PayPalService');
                                scope.setLevel(SentryScopeLevel.ERROR);
                                scope.setContext('paypal-error', error.error.PayPalError);
                                sentry.captureException(new Error(msg));
                              });
                            }
                          }
                        })
                    );
                  });
                },
                onClick: () => this._ngZone.run(() => this.paymentStarted.next()),
                createOrder: (_data, actions) => {
                  /* eslint-disable @typescript-eslint/naming-convention */
                  return actions.order.create({
                    intent: PayPalINTENT.CAPTURE,
                    payer: this._getPayPalPayer(),
                    purchase_units: this._getPayPalPurchaseUnits(),
                    application_context: this._getPayPalApplicationContext(),
                  } as IPayPalOrder);
                  /* eslint-enable @typescript-eslint/naming-convention */
                },
              })
              .render(elementRef.nativeElement)
              .then(() => resolve())
              .catch(() => reject());
          })
          .catch((error) => reject(error));
      });
    });
  }

  public loadPayPalScript(): Promise<void> {
    const payPalEnvironment = this._appService.usePayPalProd.value ? ENVIRONMENT.paypal.prod.clientID : ENVIRONMENT.paypal.sandbox.clientID;
    const components = 'buttons,marks,funding-eligibility';
    const currency = 'EUR';
    const locale = this._translationService.locale === 'de' ? 'de_DE' : 'en_GB';

    if (!this._payPalScriptElement || !window.paypal) {
      return new Promise((resolve) => {
        this._payPalScriptElement = document.createElement('script');
        this._payPalScriptElement.src = `https://www.paypal.com/sdk/js?client-id=${payPalEnvironment}&components=${components}&currency=${currency}&locale=${locale}`;
        this._payPalScriptElement.onload = (): void => resolve();
        document.body.appendChild(this._payPalScriptElement);
      });
    } else {
      return Promise.resolve();
    }
  }

  public removePayPalScript(): void {
    if (this._payPalScriptElement) {
      document.body.removeChild(this._payPalScriptElement);
      this._payPalScriptElement = undefined;
    }
  }

  private _openErrorDialog(): void {
    if (this._paypalFeedBackDialog) {
      this._paypalFeedBackDialog.close();
    }

    this._paypalFeedBackDialog = this._dialogService.openDialog(ErrorDialogComponent, {
      hasBackdrop: true,
      data: {
        errorIcon: 'enthus-credit_card_off',
        errorIconIsPortal: true,
        errorInfoMessage: this._translationService.translations.error.PayPal.TransactionError.toString(),
        errorHeader: this._translationService.translations.headers.PayPal.PaymentError.toString(),
        feedbackCategoryId: 4,
        feedbackDialogHeader: this._translationService.translations.headers.PayPal.PaymentFeedback.toString(),
      },
    });
  }

  private _openFeedbackDialog(): void {
    if (this._paypalFeedBackDialog) {
      this._paypalFeedBackDialog.close();
    }

    this._paypalFeedBackDialog = this._dialogService.openDialog(PayPalPaymentCancelledComponent, {
      hasBackdrop: true,
    });
  }

  private _getPayPalPurchaseUnits(): IPayPalPurchaseUnit[] {
    let itemTotal = 0;
    let taxTotal = 0;
    const shipping = 0;
    const handling = 0;
    const insurance = 0;
    const shippingDiscount = 0;
    const discount = 0;

    /* eslint-disable @typescript-eslint/naming-convention */
    const purchaseUnit: IPayPalPurchaseUnit = {
      amount: this._getPayPalAmountWithBreakdown(),
      items: [],
      description: this._cartService.cart$.value.CartDescription,
      shipping: this._getPayPalShipping(),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    // Process all items in the cart
    this._cartService.cart$.value.CartPositions.forEach((cartPosition) => {
      const price: number = cartPosition.PositionSalesPrice;
      const quantity: number = cartPosition.PositionItemQuantity;
      const tax: number = this._cartService.cart$.value.PaymentHasTax ? cartPosition.PositionSalesPriceInclusiveVAT - price : 0;

      /* eslint-disable @typescript-eslint/naming-convention */
      const purchaseUnitItem: IPayPalPurchaseUnitItem = {
        name: cartPosition.PositionItemDescription || cartPosition.PositionItemNo,
        unit_amount: {
          currency_code: PayPalCurrencyCodes.EUR,
          value: price.toFixed(2),
        },
        tax: {
          currency_code: PayPalCurrencyCodes.EUR,
          value: tax.toFixed(2),
        },
        quantity: quantity.toString(),
        description: cartPosition.PositionPaypalDescription,
      };
      /* eslint-enable @typescript-eslint/naming-convention */

      purchaseUnit.items.push(purchaseUnitItem);

      itemTotal += quantity * price;
      taxTotal += quantity * tax;
    });

    // Calculate and set the totals
    purchaseUnit.amount.breakdown.item_total.value = itemTotal.toFixed(2);
    purchaseUnit.amount.breakdown.tax_total.value = taxTotal.toFixed(2);
    purchaseUnit.amount.breakdown.shipping.value = '0.00';

    // (item_total + tax_total + shipping + handling + insurance) - shipping_discount - discount
    purchaseUnit.amount.value = (itemTotal + taxTotal + shipping + handling + insurance - shippingDiscount - discount).toFixed(2);

    return [purchaseUnit];
  }

  private _getPayPalPayer(): IPayPalPayer {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      email_address: this._appService.usePayPalProd ? this._userService.currentUser$?.value?.UserEMailAddress : ENVIRONMENT.paypal.sandbox.buyer.email,
      name: {
        given_name: this._userService.currentUser$?.value?.UserFirstName,
        surname: this._userService.currentUser$?.value?.UserLastName,
      },
      address: {
        address_line_1: this.deliveryAddress.AddressStreet,
        // address_line_2: '',
        // admin_area_1: '',
        admin_area_2: this.deliveryAddress.AddressTown,
        postal_code: this.deliveryAddress.AddressZipCodeNo,
        country_code: 'DE', // TODO: This value should be transmitted by the backend within address endpoints
      },
    };
    /* eslint-enable @typescript-eslint/naming-convention */
  }

  private _getPayPalApplicationContext(): IPayPalApplicationContext {
    /* eslint-disable @typescript-eslint/naming-convention */
    return {
      landing_page: PayPalLandingPage.LOGIN,
      locale: this._translationService.locale === 'de' ? 'de-DE' : 'en-GB',
      shipping_preference: PayPalShippingPreference.SET_PROVIDED_ADDRESS,
      user_action: PayPalUserAction.PAY_NOW,
      brand_name: 'enthus',
      payment_method: {
        payee_preferred: PayPalPayeePreferred.IMMEDIATE_PAYMENT_REQUIRED,
      },
    };
    /* eslint-enable @typescript-eslint/naming-convention */
  }

  private _getPayPalButtonStyle(): IPayPalButtonStyle {
    return {
      color: 'blue',
      shape: 'rect',
      size: 'large',
    };
  }

  private _getPayPalAmountWithBreakdown(): IPayPalAmountWithBreakdown {
    /* eslint-disable @typescript-eslint/naming-convention */
    return {
      currency_code: PayPalCurrencyCodes.EUR,
      value: null,
      breakdown: {
        item_total: {
          currency_code: PayPalCurrencyCodes.EUR,
          value: null,
        },
        tax_total: {
          currency_code: PayPalCurrencyCodes.EUR,
          value: null,
        },
        shipping: {
          currency_code: PayPalCurrencyCodes.EUR,
          value: null,
        },
      },
    };
    /* eslint-enable @typescript-eslint/naming-convention */
  }

  private _getPayPalShipping(): IPayPalShipping {
    /* eslint-disable @typescript-eslint/naming-convention */
    return {
      name: {
        full_name: this.deliveryAddress.AddressName1,
      },
      address: {
        address_line_1: this.deliveryAddress.AddressStreet,
        // address_line_2: '',
        // admin_area_1: '',
        admin_area_2: this.deliveryAddress.AddressTown,
        postal_code: this.deliveryAddress.AddressZipCodeNo,
        country_code: 'DE', // TODO: This value should be transmitted by the backend within address endpoints
      },
    };
    /* eslint-enable @typescript-eslint/naming-convention */
  }
}
